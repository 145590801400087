import React, { Component } from 'react'

import { FormControl, Card, InputGroup, Form, Row, Col, Image } from 'react-bootstrap'
import '../../Asset/scss/Testdrive.scss'
import contact from '../../Asset/img/contact/contact1.jpg'
import thai from '../../Asset/img/insurance/logoin.PNG'
import line from '../../Asset/img/line.png'
import { GET, POST } from '../../service'
import { async } from 'q';
import swal from 'sweetalert';
import moment from 'moment'

export default class Contactservice extends Component {
    constructor(props) {
        super(props)
        this.state = {

            contactservice: {
                name_lastname: "",
                phone: "",
                id_line: "",
                email: "",
                car_number: "",
                date: "",
                detail: "",
                branch: "",
                appoint: "",
                time: "10:00"

            },

        }
    }
    componentDidMount = async () => {

    }
    handleChange = (action, value) => {
        let { contactservice } = this.state
        contactservice[action] = value
        this.setState({
            contactservice
        });
    }
    handleSubmit = async () => {
        try {
            let { contactservice } = this.state
            contactservice.date = contactservice.date + " " + contactservice.time
            this.setState({ contactservice })
            await POST('appointment', this.state.contactservice)

            swal({
                title: "ส่งข้อมูลสำเร็จ",
                icon: "success"
            });
            this.setState({
                contactservice: {
                    name_lastname: "",
                    phone: "",
                    id_line: "",
                    email: "",
                    car_number: "",
                    date: "",
                    detail: "",
                    branch: "",
                    appoint: "",
                    time:""
                }
            })

        } catch (error) {
            swal({
                title: "เกิดข้อผิดพลาดกรุณาลองใหม่",
                icon: "warning",
            });
        }
    }
    render() {

        return (
            <div className="FontHeader" >
                <div className="header header-50">
                    <div className="header-title">นัดหมายเช็คระยะ</div>
                </div>

                <div className="testdrive">
                    <div className="testdrive-content" >
                        <Card style={{ border: 'unset' }}>
                            <Row>
                                <Col lg={6} md={12} sm={12} >
                                    <Card.Body >
                                        <Card.Title style={{ color: 'black' }}><h4><b>บริการของเรา</b></h4></Card.Title>
                                        <Card.Text style={{ color: 'black', paddingTop: '1rem' }}>"บริการด้านการขาย อะไหล่ ศูนย์ซ่อมรถยนต์ และศูนย์ซ่อมสีและตัวถังมาตรฐาน
                                            โดยทีมงานมืออาชีพผ่านการอบรมทักษะจากมิตซูบิชิมอเตอร์ประเทศไทย ทั้งนี้ศูนย์บริการของเรายังพร้อมทั้งด้าน สถานที่ เครื่องมือ
                                            เทคโนโลยีที่ล้ำสมัย พร้อมด้านทีมงานมากประสบการณ์เพื่อสามารถตอบสนองความพึงพอใจสูงสุดของลูกค้า "</Card.Text>

                                    </Card.Body>
                                </Col>
                                <Col lg={6} md={12} sm={12} >
                                    <Card.Img variant="top" src={contact} style={{ objectFit: "cover", objectPosition: "center", maxHeight: '22rem' }} />
                                </Col>
                            </Row>
                        </Card>
                        {/* <Form> */}
                        <h5 ><b>กรอกข้อมูลเพื่อรับบริการ</b></h5>
                        <Row>
                            <Col lg={12} sm={12} >
                                <Form.Group>
                                    <Form.Control onChange={(e) => this.handleChange("name_lastname", e.target.value)} value={this.state.contactservice.name_lastname} placeholder="ชื่อลูกค้า" />
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="basic-addon1" style={{ width: "3rem", backgroundColor: 'unset' }}>
                                                <Image style={{ width: '1.6rem' }} rel="nofollow" src={thai} ></Image>
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl type="number" onChange={(e) => this.handleChange("phone", e.target.value)} value={this.state.contactservice.phone} placeholder="เบอร์โทรศัพท์" />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="basic-addon1" style={{ width: "3rem", backgroundColor: 'unset' }}>
                                                <Image style={{ width: '1.6rem' }} rel="nofollow" src={line} ></Image>
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control onChange={(e) => this.handleChange("id_line", e.target.value)} value={this.state.contactservice.id_line} placeholder="ID Line" />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col lg={12} sm={12} >
                                <Form.Group>
                                    <Form.Control onChange={(e) => this.handleChange("email", e.target.value)} value={this.state.contactservice.email} placeholder="Email" />
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group>
                                    <Form.Control onChange={(e) => this.handleChange("car_number", e.target.value)} value={this.state.contactservice.car_number} placeholder="ทะเบียนรถ" />
                                </Form.Group>
                            </Col>
                            <Col lg={3} sm={6} >
                                <Form.Group>
                                    <InputGroup>
                                        <Form.Control type='date'
                                            onChange={(e) => this.handleChange("date", e.target.value)} value={this.state.contactservice.date}
                                            placeholder="วันที่เข้ารับบริการ" />
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                {/* <FaCalendarAlt /> */}
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Text className="text-muted">
                                        วันที่เข้ารับบริการ ตัวอย่าง(01/01/2021)
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={3} sm={6} >
                                <Form.Group>
                                    <InputGroup>
                                        <Form.Control type='time'
                                            onChange={(e) => this.handleChange("time", e.target.value)} value={this.state.contactservice.time}
                                            placeholder="เวลาเข้ารับบริการ" />
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                {/* <FaCalendarAlt /> */}
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Text className="text-muted">
                                        เวลาเข้ารับบริการ ตัวอย่าง(10:00)
                                    </Form.Text>
                                </Form.Group>
                            </Col>

                            <Col lg={12} sm={12} >
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Control onChange={(e) => this.handleChange("appoint", e.target.value)} value={this.state.contactservice.appoint} as="select" >
                                        <option value="">กรุณาเลือกประเภทนัดหมาย</option>
                                        <option value="นัดหมายเช็คระยะ">นัดหมายเช็คระยะ</option>
                                        <option value="นัดหมายซ่อม">นัดหมายซ่อม</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col lg={12} sm={12} >
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Control onChange={(e) => this.handleChange("branch", e.target.value)} value={this.state.contactservice.branch} as="select" >
                                        <option value="">กรุณาเลือกสถานที่ต้องการเข้าเช็ค</option>
                                        <option value="สำนักงานใหญ่">สำนักงานใหญ่</option>
                                        {/* <option value="สาขาบ้านผือ">สาขาบ้านผือ</option> */}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col lg={12} sm={12} >
                                <Form.Group>
                                    <Form.Text className="text-muted">
                                        ข้อความเพิ่มเติม
                                    </Form.Text>
                                    <InputGroup>
                                        <FormControl as="textarea" aria-label="With textarea" onChange={(e) => this.handleChange("detail", e.target.value)} value={this.state.contactservice.detail} />
                                    </InputGroup>
                                </Form.Group>
                            </Col>

                            <Col className="d-flex justify-content-center">
                                <button className="buttonTest" onClick={() => this.handleSubmit()}>
                                    ส่งข้อมูลถึงเรา </button>
                            </Col>
                        </Row>
                        {/* </Form> */}
                    </div>
                </div>

            </div >

        )
    }
}
