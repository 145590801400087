import React, { Component } from 'react'
import '../../Asset/scss/Service.scss'

export default class Paint extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="header header-transparent">
                    <div className="header-title">ศูนย์ซ่อมสี และตัวถัง</div>
                </div>
                <div className="paint-header">
                    <p><strong>ศุนย์ซ่อมสีและตัวถังมาตรฐานมิตซูโชคอนันต์</strong> จากประสบการณ์เราพร้อมให้บริการครอบคลุมพื้นที่จังหวัด กาฬสินธุ์
                    พร้อมช่างผู้ชำนาญการที่ผ่านการอบรมจากบริษัท มิตซูบิชิ มอเตอร์ส (ประเทศไทย) จำกัด
                    มากด้วยอุปกรณ์ที่ครบครันและมีความทันสมัยที่สุด ไม่ว่าจะเป็นอุปกรณ์ดึงรถยนต์ที่มีความแข็งแรงทนทาน
                            ทันสมัย และตู้ผสมสีที่ได้มาตรฐาน</p>
                </div>
                <div className="paint-service">
                    <div className="paint-service-title">บริการของเรา</div>
                    <p>1. บริการซ่อมสีและตัวถังมาตรฐานมิตซูบิชิ (Diamond Body&Paint) รับประกันงานซ่อมสี 12 เดือน</p>
                    <p>2. บริการรับซ่อมสีประกันภัยที่ร่วมโครงการ Diamond Body&Paint</p>
                    <p>3. บริการรับซ่อมสีประกันภัยที่ร่วมกับบริษัท มิตซูโชคอนันต์ จำกัด</p>
                    <p>4. บริการอะไหล่แท้จากมิซูบิชิ 100%</p>
                </div>
                <div className="paint-lounge">
                    <div className="paint-lounge-title">ห้องรับรองลูกค้า</div>
                    <p>ห้องรับรองลูกค้าพร้อมสิ่งอำนวยความสะดวกครบครัน  เพื่อบริการลูกค้า
                    ทั้งคอมพิมเตอร์พร้อม Ji Speed Internet,
                    มุมสำหรับพักผ่อน,
                    อาหารว่างพร้อมเครื่องดื่มฟรี ขณะรอในห้องรับรอง</p>
                    <div className="paint-lounge-img">
                        <img src={require('../../Asset/images/service/s1.jpg')} alt="" />
                    </div>
                </div>
                <div className="paint-lounge">
                    <div className="paint-lounge-title2">บริษัทประกันภัยคู่กับสัญญามิตซูโชคอนันต์</div>
                    <div className="paint-lounge-img">
                        <img src={require('../../Asset/images/service/ins.jpg')} alt="" />
                    </div>
                </div>
                <div className="paint-blackground">
                    <img src={require('../../Asset/images/service/IMG_0495.jpg')} alt="" />
                </div>
            </React.Fragment>
        )
    }
}
