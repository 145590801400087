import React, { useEffect, useState } from 'react'
import './Style.scss'
import NavLink from '../Utils/NavLink'

function useScrollDirection() {
    const [oldScrollPos, setOldScrollPos] = useState(0)

    useEffect(() => {
        function onScroll() {
            setOldScrollPos(window.pageYOffset)
        }
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    return oldScrollPos
}

export default function NavigationBar() {
    const [showMenu, setShowMenu] = useState(false)
    const scrollUp = useScrollDirection()

    return (
        <div className={`navbar ${scrollUp > 80 ? 'abs' : ''}`}>
            <div className="navbar-left">
                <div className="navbar-logo-mitsu">
                    <img src={require('../../Asset/images/logo/logo_mitsu.png')} alt="Mitsubishi Motors" />
                </div>
                <button className={`navbar-hamberger_icon ${showMenu ? 'active' : ''}`} onClick={() => setShowMenu(!showMenu)}>menu</button>
                <ul className="navbar-menu">
                    <li>
                        <NavLink to="/" classActive="active"> หน้าหลัก</NavLink>
                    </li>
                    <li>
                        <NavLink to="/about" classActive="active"> เกี่ยวกับเรา</NavLink>
                    </li>
                    <li>
                        <NavLink to="/products" classActive="active"> รุ่นรถยนต์</NavLink>
                    </li>
                    <li className="dropdown">
                        <NavLink tag="/service" classActive="active" className="btn"> บริการ</NavLink>
                        <div className="dropdown-content">
                            <NavLink to="/service/center">ศูนย์บริการ</NavLink>
                            <NavLink to="/service/paint">ศูนย์ซ่อมสี และตัวถัง</NavLink>
                            <NavLink to="/service/contact_for_service">นัดหมายเช็คระยะ</NavLink>
                        </div>
                    </li>
                    <li>
                        <NavLink to="/promotion" classActive="active"> โปรโมชันพิเศษ</NavLink>
                    </li>
                    <li>
                        <NavLink to="/news" classActive="active"> ข่าวสารและกิจกรรม</NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" classActive="active"> ติดต่อเรา</NavLink>
                    </li>
                    <li className="dropdown">
                        <NavLink tag="/other" classActive="active" className="btn"> เพิ่มเติม</NavLink>
                        <div className="dropdown-content">
                            <NavLink to="/other/guaranteeusedcar">Guarantee Used Car</NavLink>
                            <NavLink to="/other/insurance">ต่อประกันภัย</NavLink>
                            <NavLink to="/other/testdrive">นัดหมายทดลองขับ</NavLink>
                            {/* <NavLink to="/service/contact_for_service">แจ้งเคลมประกัน</NavLink> */}
                        </div>
                    </li>
                </ul>

            </div>
            <div className="navbar-right navbar-logo-udon">
                <img src={require('../../Asset/images/logo/logo4.png')} alt="มิตซูโชคอนันต์" />
            </div>
        </div>
    )
}