import React, { Component } from 'react'

import { FormControl, Card, InputGroup, Form, Row, Col, Image } from 'react-bootstrap'
import '../../Asset/scss/Testdrive.scss'
import test from '../../Asset/img/Products/test.jpg'
import thai from '../../Asset/img/insurance/logoin.PNG'
import { GET, POST } from '../../service'
import { async } from 'q';
import swal from 'sweetalert';

export default class Contactservice extends Component {
    constructor(props) {
        super(props)
        this.state = {
            testDrive: {
                first: "",
                last: "",
                email: "",
                tel: "",
                id_car: "",
                date_test: "",
                branch: ""
            },
            cars: []
        }
    }
    componentDidMount = async () => {
        try {
            let cars = await GET("car_detail")
            this.setState({
                cars: cars
            })

        } catch (error) {

        }
    }
    handleChange = (action, value) => {
        let { testDrive } = this.state
        testDrive[action] = value
        this.setState({
            testDrive
        });
    }
    handleSubmit = async () => {
        try {
            let check = await POST('create_test', this.state.testDrive)

            swal({
                title: "ส่งข้อมูลสำเร็จ",
                icon: "success"
            });
            this.setState({
                testDrive: {
                    first: "",
                    last: "",
                    email: "",
                    tel: "",
                    id_car: "",
                    date_test: "",
                    branch: ""
                }
            })
        } catch (error) {
            swal({
                title: "เกิดข้อผิดพลาดกรุณาลองใหม่",
                icon: "warning",
            });
        }
    }
    render() {

        const { cars } = this.state
        return (
            <div className="FontHeader" >
                <div className="header header-50">
                    <div className="header-title">ทดลองขับรถยนต์</div>
                </div>

                <div className="testdrive">
                    <div className="testdrive-content" >
                        <Card style={{ border: 'unset' }}>
                            <Row>
                                <Col lg={6} md={12} sm={12} >
                                    <Card.Body >
                                        <Card.Title style={{ color: 'black' }}><h4><b>บริการของเรา</b></h4></Card.Title>
                                        <Card.Text style={{ color: 'black', paddingTop: '1rem' }}>"บริการด้านการขาย อะไหล่ ศูนย์ซ่อมรถยนต์ และศูนย์ซ่อมสีและตัวถังมาตรฐาน
                                        โดยทีมงานมืออาชีพผ่านการอบรมทักษะจากมิตซูบิชิมอเตอร์ประเทศไทย ทั้งนี้ศูนย์บริการของเรายังพร้อมทั้งด้าน สถานที่ เครื่องมือ
                                        เทคโนโลยีที่ล้ำสมัย พร้อมด้านทีมงานมากประสบการณ์เพื่อสามารถตอบสนองความพึงพอใจสูงสุดของลูกค้า "</Card.Text>

                                    </Card.Body>
                                </Col>
                                <Col lg={6} md={12} sm={12} >
                                    <Card.Img variant="top" src={test} style={{ objectFit: "cover", objectPosition: "center", maxHeight: '22rem' }} />
                                </Col>
                            </Row>
                        </Card>
                        {/* <Form> */}
                        <h5 ><b>กรอกข้อมูลเพื่อรับบริการ</b></h5>
                        <Row>
                            <Col lg={6} sm={12} >
                                <Form.Group>
                                    <Form.Control onChange={(e) => this.handleChange("first", e.target.value)} value={this.state.testDrive.first} placeholder="ชื่อลูกค้า" />
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group>
                                    <Form.Control onChange={(e) => this.handleChange("last", e.target.value)} value={this.state.testDrive.last} placeholder="นามสกุล" />
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group>
                                    <Form.Control onChange={(e) => this.handleChange("email", e.target.value)} value={this.state.testDrive.email} placeholder="Email" />
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="basic-addon1" style={{ width: "3rem", backgroundColor: 'unset' }}>
                                                <Image style={{ width: '1.6rem' }} rel="nofollow" src={thai} ></Image>
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl type="number" onChange={(e) => this.handleChange("tel", e.target.value)} value={this.state.testDrive.tel} placeholder="เบอร์โทรศัพท์" />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Control onChange={(e) => this.handleChange("id_car", e.target.value)} value={this.state.testDrive.id_car} as="select" >
                                        <option value="">กรุณาเลือกรุ่นรถยนต์ที่สนใจ</option>
                                        {cars.map((index, i) =>
                                            <option value={index.id_car}>{index.name_car}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Control onChange={(e) => this.handleChange("branch", e.target.value)} value={this.state.testDrive.branch} as="select" >
                                        <option value="">กรุณาเลือกสาขาที่ทดลองขับ</option>
                                        <option value="สำนักงานใหญ่">สำนักงานใหญ่</option>
                                        {/* <option value="สาขาบ้านผือ">สาขาบ้านผือ</option> */}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col lg={12} sm={12} >
                                <Form.Group>
                                    <InputGroup>
                                        <Form.Control type='datetime-local' onChange={(e) => this.handleChange("date_test", e.target.value)} value={this.state.testDrive.date_test} placeholder="วันที่กรมธรรม์หมดอายุ" />
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                {/* <FaCalendarAlt /> */}
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Text className="text-muted">
                                        วันที่นัดหมายทดลองขับ
                                        </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <button className="buttonTest" onClick={() => this.handleSubmit()}>
                                    ส่งข้อมูลถึงเรา </button>
                            </Col>
                        </Row>
                        {/* </Form> */}
                    </div>
                </div>

            </div >

        )
    }
}
