import React, { Component } from 'react'
import '../Asset/scss/About.scss'

export default class About extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="about-title">เกี่ยวกับเรา</div>
                <div className="about-definition">
                    “เราพร้อมแล้วที่จะมุ่งมั่นพัฒนาคุณภาพบุคลากรพร้อมบริการทุกระดับ <br />บริการทุกท่านด้วยรอยยิ้มและความเต็มใจ”
                </div>
                <div className="about-content">
                    <div className="about-content-img">
                        <img src={require('../Asset/images/about/IMG_5836.png')} alt="" />
                    </div>
                    <div className="about-content-detail">
                        <p>               
                            บริษัท มิตซูโชคอนันต์ จำกัด เป็นตัวแทนจำหน่ายรถยนต์มิตซูบิชิ 
                            เปิดให้บริการตั้งแต่ปี พ.ศ. 2556 ให้บริการครอบคลุมพื้นที่จังหวัดกาฬสินธุ์และพื้นที่ใกล้เคียงอย่างครบวงจร 
                            โดยพนักงานการขาย ศูนย์บริการ และศูนย์อะไหล่ โดยพนักงานทั้งฝ่ายขาย ศูนย์บริการ 
                            ได้ผ่านการอบรมขั้นสูงจากศูนย์ฝึกอบรมมิตซูบิชิมอเตอร์ ประเทศไทย 
                    </p>
                        <p>
                            ตลอดระยะเวลาที่ผ่านมา เรามุ่งมั่นพัฒนาคุณภาพการบริการให้มีมาตรฐาน 
                            รับประกันด้วยรางวัล President Awards ผู้จำหน่ายยอดเยี่ยมในระดับโชว์รูมขนาดเล็ก ปี 2562 และ 2563 
                            ทำให้ลูกค้าทุกท่านมั่นใจได้ว่าจะได้รับการบริการด้วยความเต็มใจจากทีมงานมิตซูโชคอนันต์ ดังสโลแกนว่า มิตซูโชคอนันต์  “ ทำงานดี มีระบบ บริการครบ รับรถอุ่นใจ “
                    </p>
                    </div>
                </div>
                <div className="about-content d-flex justify-content-between flex-wrap">
                    <div className="content-card">
                        <div className="content-card-title">วิสัยทัศน์</div>
                        <div className="content-card-detail">
                            <div className="content-card-img">
                                <img src={require('../Asset/images/about/IMG_5867.jpg')} alt="" />
                            </div>
                            <p>“มิตซูโชคอนันต์” ทำงานดี มีระบบ บริการครบ รับรถอุ่นใจ บริการทุกระดับประทับใจทุกท่านคือลูกค้า VIP</p>
                        </div>
                    </div>
                    <div className="content-card">
                        <div className="content-card-title">คำสโลแกนของบริษัท</div>
                        <div className="content-card-detail">
                            <div className="content-card-img">
                                <img src={require('../Asset/images/about/about12.png')} alt="" />
                            </div>
                            <p>“มุ่งพัฒนาสู่การเป็นองค์กรชั้นนำในธุรกิจรถยนต์ โดยเน้นการร่วมกันทำงานเป็นทีมเพื่อให้เกิดการบริการที่ดีและประทับใจ”</p>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}
